
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  



  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://1f948ce411734c10b70ac012f7560057@sentry.wixpress.com/3604',
      id: '1f948ce411734c10b70ac012f7560057',
      projectName: 'form-example',
      teamName: 'form-example',
      errorMonitor: true,
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/6dca45ae3e6ec396/packages/form-example/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "form-example",
    biConfig: null,
    appName: "Form Example",
    appDefinitionId: "813e0fc5-89be-48a2-89c0-5fd74d4beebd",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/components/FormExampleWidget/controller.ts",
          appName: "Form Example",
          appDefinitionId: "813e0fc5-89be-48a2-89c0-5fd74d4beebd",
          projectName: "form-example",
          componentName: "FormExampleWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "1da3dd6c-a1c8-4ea4-a026-0b7ce859f309" }, { method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/components/SledTests/controller.ts",
          appName: "Form Example",
          appDefinitionId: "813e0fc5-89be-48a2-89c0-5fd74d4beebd",
          projectName: "form-example",
          componentName: "SledTests",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "46999185-c313-4712-94e5-762a8b72cbad" }, { method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/components/StatelessForm/controller.ts",
          appName: "Form Example",
          appDefinitionId: "813e0fc5-89be-48a2-89c0-5fd74d4beebd",
          projectName: "form-example",
          componentName: "StatelessForm",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "30935fd4-3355-4939-92a4-7d9dd5c8ffd1" }],
    false);

    export const createControllers = _createControllers
